<script
    lang="ts"
    setup
>
    type Props = {
        errorMessage?: string
        disabled?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        errorMessage: '',
        disabled: false
    })
</script>

<template>
    <Transition name="field-error-message-transition">
        <span
            v-if="!props.disabled && props.errorMessage"
            key="error-message"
            :class="$style['field-error-message']"
        >
            {{ props.errorMessage }}
        </span>
    </Transition>
</template>

<style
    lang="sass"
    module
    scoped
>
    .field-error-message
        pointer-events: none
        overflow: hidden
        display: flex
        width: 100%
        padding: 2px 16px 0
        font-style: normal
        font-weight: 400
        font-size: 12px
        line-height: 130%
        color: #dd0e0e
        will-change: max-height
</style>

<style lang="sass">
    .field-error-message-transition
        &-enter-from,
        &-leave-to
            max-height: 0

        &-enter-active,
        &-leave-active
            pointer-events: none
            transition: max-height var(--transition-default-duration-with-ease)

        &-enter-to,
        &-leave-from
            max-height: 20px
</style>
